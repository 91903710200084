import { Projekteklasse } from './../projekteklasse';
import { ApiService } from './../api.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'rk-pool',
  templateUrl: './pool.component.html',
  styleUrls: ['./pool.component.css']
})
export class PoolComponent implements OnInit {

  account: Projekteklasse[];

  constructor(private bs: ApiService) { }

  ngOnInit() {

    this.bs.loadAccount().subscribe(all => this.account = all);

  }

}
