import { Loginklasse } from './loginklasse';
import { Projekteklasse } from './projekteklasse';
import { Formulareklasse } from './formulareklasse';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';



@Injectable({
  providedIn: 'root'
})
export class ApiService {

  private registrieren = 'http://digbizmistelbach.at/c4a/api/public/registrieren';
  private projekte = 'http://digbizmistelbach.at/c4a/api/public/projekte';
  private projektneu = 'http://digbizmistelbach.at/c4a/api/public/projektneu';
  private login = 'http://digbizmistelbach.at/c4a/api/public/login';

  constructor(private http: HttpClient) { }

  regi(register: Formulareklasse) {
    return this.http.post<any>(this.registrieren, register);
  }

  loginapi(loger: Loginklasse) {
    return this.http.post<any>(this.login, loger);
  }

  proj(projektanlegen: Projekteklasse) {
    return this.http.post<any>(this.projektneu, projektanlegen);
  }

  loadAccount(): Observable<Projekteklasse[]> {
    return this.http.get<Projekteklasse[]>(this.projekte);
  }

}
