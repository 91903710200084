export class Formulareklasse {

  constructor(
    public Anrede: string,
    public Organisation: string,
    public Vorname: string,
    public Zuname: string,
    public Geschlecht: string,
    public email: string,
    public Strasse: string,
    public Postleitzahl: string,
    public Ort: string,
    public password: string,
    public Passwortwiederholen: string,
    public Telefonnummer: string,
    public Geburtsdatum: string,
    public Bundesland: string
  ) {}
  }
