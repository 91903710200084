import { MeinprofilComponent } from './meinprofil/meinprofil.component';
import { AdminComponent } from './admin/admin.component';
import { AgbComponent } from './agb/agb.component';
import { ContactComponent } from './contact/contact.component';
import { ProjektneuComponent } from './projektneu/projektneu.component';
import { RegistrierenComponent } from './registrieren/registrieren.component';
import { LoginComponent } from './login/login.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { PoolComponent } from './pool/pool.component';
import { AboutComponent } from './about/about.component';

const routes: Routes = [
  {path: '', redirectTo: 'home', pathMatch: 'full'},
  {path: 'home', component: HomeComponent},
  {path: 'pool', component: PoolComponent},
  {path: 'about', component: AboutComponent},
  {path: 'login', component: LoginComponent},
  {path: 'registrieren', component: RegistrierenComponent},
  {path: 'contact', component: ContactComponent},
  {path: 'projektneu', component: ProjektneuComponent},
  {path: 'agbs', component: AgbComponent},
  {path: 'profil', component: MeinprofilComponent},
  {path: 'admin', component: AdminComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
export const routingComponents = [HomeComponent, PoolComponent, AboutComponent];
