import { ApiService } from './api.service';
import { Component, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { Formulareklasse } from './formulareklasse';
import { Router } from '@angular/router';


@Component({
  selector: 'rk-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})

export class AppComponent {
  Bundesland = ['Niederösterreich', 'Oberösterreich', 'Wien', 'Salzburg', 'Steiermark', 'Burgenland', 'Kärnten', 'Tirol', 'Vorarlberg'];
  Geschlecht = ['Männlich', 'Weiblich'];
  Anrede = ['Herr', 'Frau'];

  FormulareklasseModel = new Formulareklasse('', '', '', '', '', '', '', '', '', '', '', '', '' , '');


  constructor(private registrieren: ApiService,
              private router: Router) {}


onSubmit() {
    this.registrieren.regi(this.FormulareklasseModel)
    .subscribe(data => {
      // tslint:disable-next-line: no-debugger
      debugger;
      this.router.navigate(['login']);
    });
}
}

/*

export class PoolComponent implements OnInit {

  topics = ['Niederösterreich', 'Oberösterreich', 'Wien', 'Salzburg', 'Steiermark', 'Burgenland', 'Kärnten', 'Tirol', 'Vorarlberg'];
  formularklasseModel = new Formulareklasse('', '', '', '', '', '' , '', '', '');
*/
