import { Router } from '@angular/router';
import { ApiService } from '@src/app/api.service';
import { Component } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';


@Component({
  selector: 'rk-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent {

  loginForm: FormGroup;
  invalidLogin = false;

  constructor(private formbuilder: FormBuilder,
              private router: Router,
              private apiService: ApiService) {}

builderForm = this.formbuilder.group({
  email: [''],
  password: ['']
});

onSubmit() {
  if (this.loginForm.invalid) {
    return;
  }
  const loginPayload = {
    email: this.loginForm.controls.email.value,
    password: this.loginForm.controls.password.value
  };
  this.apiService.loginapi(loginPayload).subscribe(data => {
    // tslint:disable-next-line: no-debugger
    debugger;
    if (data.error === false) {
      this.router.navigate(['pool']);
    } else {
     this.invalidLogin = true;
     alert(data.message);
    }
  });
}

// tslint:disable-next-line: use-lifecycle-interface
ngOnInit() {
  this.loginForm = this.formbuilder.group({
    email: [''],
    password: ['']
  });
}
}

