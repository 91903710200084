import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'rk-registrieren',
  templateUrl: './registrieren.component.html',
  styleUrls: ['./registrieren.component.css']
})
export class RegistrierenComponent implements OnInit {
  topics = ['Niederösterreich', 'Oberösterreich', 'Wien', 'Salzburg', 'Steiermark', 'Burgenland', 'Kärnten', 'Tirol', 'Vorarlberg'];
  constructor() { }

  ngOnInit() {
  }

}
