import { ApiService } from './../api.service';
import { Projekteklasse } from './../projekteklasse';
import { Component, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';


@Component({
  selector: 'rk-projektneu',
  templateUrl: './projektneu.component.html',
  styleUrls: ['./projektneu.component.css']
})
export class ProjektneuComponent {
  topics = ['Kinder', 'Natur', 'Umwelt', 'Sozial'];
  Projekteklasse = new Projekteklasse('', '', '', '', '', '');
  constructor(private Anlegen: ApiService) { }

  onSubmit() {
    this.Anlegen.proj(this.Projekteklasse)
    .subscribe(anlegenprojekt => console.log('Erfolgreich gespeichert', anlegenprojekt));
  }

}
