export class Projekteklasse {
  constructor(
    public Titel: string,
    public Kurzbeschreibung: string,
    public Detailbeschreibung: string,
    public Organisation: string,
    public Budgetvorschlag: any,
    public Thema: string,
  ) {}
}
